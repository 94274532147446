<script>
  export let startDate = new Date()
  export let endDate = new Date()
  export let minDate=null
  export let maxDate=null

  function toLocalDate(date){
    date=new Date(date)
    const offsetMs = date.getTimezoneOffset() * 60 * 1000
    const msLocal =  date.getTime() - offsetMs
    const dateLocal = new Date(msLocal)
    const iso = dateLocal.toISOString()
    return iso.slice(0, 10)
  }

  //to get only date part in current TZ
  startDate= toLocalDate(startDate)
  endDate= toLocalDate(endDate)
  minDate= toLocalDate(minDate)
  maxDate= toLocalDate(maxDate)

  function dateChange(item) {
    if (item == "endDate" && endDate && endDate < startDate)
      startDate = endDate

    if (item == "startDate" && startDate && startDate > endDate && endDate)
      endDate = startDate;
  }
</script>


<div class="row">
  <div class="col input-group input-group">
    <input
      type="date"
      class="form-control"
      min={minDate}
      max={maxDate}
      bind:value={startDate}
      on:input={() => {dateChange('startDate');}} />
  </div>

  <div class="col input-group input-group">
    <input
      type="date"
      class="form-control"
      min={minDate}
      max={maxDate}
      bind:value={endDate}
      on:input={() => {dateChange('endDate');}} />
    </div>
</div>