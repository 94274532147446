<script>
  import { lang, user } from './stores.js'
  import { i18n, asArray, hasRoles, setRoute } from './h.js'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Button from './G_Button.svelte'
  import _isEqual from 'lodash/isEqual'

  export let step=false
  export let filter=false //current filter
  export let sortBy=false //current sortBy
  export let steps=[]
  export let filters=[] //list of possible filters
  export let fields=[] //list of possible fields

  export let tags=false
  export let tagsSelected=[]
  export let search=false
  export let placeholder="Search"
  export let keyword=''
  export let create=false
  export let createRoute=false
  export let createRoles=false
  export let createLabel='Create'
  export let createBulk=false
  export let createBulkRoles=false
  export let createBulkLabel=''
  export let dl=false
  export let dlLabel='Download'
  export let dlRoles=false


  function toggleFilter(index) {
    let selectedFilter = filters[index].filter;
    // Toggle each key-value pair in the selected filter
    for (let key in selectedFilter) {
      if (key in filter && filter[key] === selectedFilter[key]) {
        delete filter[key];
      } else {
        filter[key] = selectedFilter[key];
      }
    }
    // Check if the filter should be completely cleared
    if (Object.keys(selectedFilter).length === 0) {
      filter = {};
    } else filter=filter;    
  }

  function isActive(filterItem) {
    if (Object.keys(filterItem.filter).length === 0 && Object.keys(filter).length !== 0) return false;
    return Object.keys(filterItem.filter).every(key => filter[key] === filterItem.filter[key]);
  }

  function sortCycle(field) {
    if (!field.nosort && field.cond!==false){
      if (sortBy[field.id] === undefined) sortBy[field.id] = 1;
      else if (sortBy[field.id] === 1) sortBy[field.id] = -1;
      else {
        delete sortBy[field.id];
        sortBy = sortBy;
      }
    }
  }

</script>

<div class="non-selectable" class:line={filters.length || fields.length || steps.length}>
  <slot/>

  {#if steps.length}
    <div>
      {#each steps as v}
        {#if v.cond!==false}
          <div class:active={v.step==step} on:click={()=>{step=v.step}}><I18n id={v.title}/></div>
        {/if}
      {/each}
    </div>
  {/if}

  {#if filters.length}
    <div>
      {#each filters as filterItem, index}
        {#if filterItem.cond!==false}
          <div 
            class:active={isActive(filterItem, filter)}
            on:click={() => toggleFilter(index)}>
            <span class="ucFirst"><I18n id={filterItem.title}/></span>
          </div>
        {/if}
      {/each}
    </div>
  {/if}

  {#if fields.length}
    <div>
      {#each fields as field}
        {#if !field.nosort && field.cond!==false}
          <div class="pointer" on:click={()=>sortCycle(field)}>
            <span class="ucFirst"><I18n id={field.title}/></span>
            {#if sortBy[field.id]==-1}<I sort-up solid />
            {:else if sortBy[field.id]==1}<I sort-down solid />
            {:else}<I sort />{/if}
          </div>
        {/if}
      {/each}
    </div>
  {/if}

</div>

{#if tags || search || create || createRoute}
  <div class="row">
    {#if tags}
      <div class="col-12 col-lg-12 mb-1">
        {#each asArray(tags) as tag (tag)}
          <input id="tag_{tag.id || tag}" class="tag" type="checkbox" bind:group={tagsSelected} value={tag.id || tag}>
          <label class="mb-1 me-1" for="tag_{tag.id || tag}">{tag.name || tag}</label>
        {/each}
      </div>
    {/if}
    {#if search || create || createRoute}
      <div class="ms-auto col-12 col-lg-12 mb-1">
        <div class="fsContentHorizontal">
          {#if search}
            <div class="me-1 col">
              <div class="input-group">
                <span class="input-group-text"><I search/></span>
                <input type="text" id={"input_" + Math.random().toString(36).substr(2, 10)} bind:value={keyword} class="form-control" placeholder={i18n(placeholder, $lang)} aria-label="Search" aria-describedby="button-search">
              </div>
            </div>
          {/if}
          {#if (create || createRoute) && hasRoles(createRoles, $user)}
            <div class="col-auto">
              <Button action={()=>{if(create) create(); if(createRoute) setRoute(createRoute)}} actionModel="add" title={createLabel}/>
            </div>
          {/if}
          {#if createBulk && hasRoles(createBulkRoles, $user)}
            <div class="col-auto ms-1">
              <Button action={createBulk} actionModel="bulk" title={createBulkLabel}/>
            </div>
          {/if}
          {#if dl && hasRoles(dlRoles, $user)}
            <div class="col-auto ms-1">
              <Button action={dl} actionModel="dl" title={dlLabel}/>
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </div>
{/if}

<style>
  
  .line {  
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 0.5rem;
  }

  .line :global(>div) {  
    display: flex;
    flex-wrap: wrap;
  }

  .line :global(>div>div.active) {
    border-bottom: 4px solid var(--brandColor) !important;
  }
  .line :global(>div>div:hover){
    border-bottom: 4px solid var(--brandColor2);
  }
  .line :global(>div>div.pointer:hover){
    border-bottom: unset!important;
  }

  .line :global(>div>div) {
    white-space: nowrap;
    padding-bottom: 2px;
    cursor: pointer;
    margin-right: 1rem;
  }


  input[type="checkbox"].tag {
    display: none;
  }
  input[type="checkbox"].tag:hover + label {
    border: 1px solid var(--brandColor);
    background-color: var(--brandColor);
    opacity:0.5;
  }
  input[type="checkbox"].tag + label {
    cursor:pointer;
    /*apadding: 0.34rem 0.5rem;*/
    padding: 0rem 0.5rem;
    font-size:0.8rem;
    border: 1px dashed var(--brandColor);
  }
  input[type="checkbox"].tag:checked + label {
    border: 1px solid var(--brandColor);
    background-color: var(--brandColor);
    color:white;
  }
</style>