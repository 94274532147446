<script>
  import { customerId, route, routeReload, conf, token, userId, wsc } from './stores.js'
  import { init, cssVars, thisYear }  from './h.js'  
  
  
  import Auth from './G_Auth.svelte'

  //import FontSelector from './G_FontSelector.svelte'
  import Offline from './G_Offline.svelte'
  import Spinner from './G_Spinner.svelte'
  import NavBarMenu from './G_NavBarMenu.svelte'
  import DivButtonMenu from './G_DivButtonMenu.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import Modal from './G_Modal.svelte'
  import Alert from './G_Alert.svelte'
  import Route from './G_Route.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'

  import Account from './G__Account.svelte'
  import Vendor from './G__Vendor.svelte'
  import Translations from './G__Translations.svelte'
  import Profil from './G__Profil.svelte'

  export let localConf = {}
  export let menus=[]

  init(localConf)
  $: cssVars($conf.style)
</script>

<!-- global -->

<!--FontSelector/-->
<Offline global/>
<Spinner global/>
<Modal/>

<!-- signin -->
<Auth/>

<!-- full screen app -->
<Route valid={$userId} Fade>
  <!--{#key $customerId || $userId || $roles}-->
  <div class="fs">
    <!-- menu -->
      <NavBarMenu sideBreak='xxl' {menus}/>
      <CustomerBanner {menus}/>

    <!-- global alert -->
    <Alert class="container-lg"/>

    <!-- content -->
    <div class="fsContent"> 

      <slot/>
      
      <Route path="/profil/*" fsContent>
        <Profil/>
      </Route>

      <Route path="/translations/*" fsContent requiredRoles={menus} let:menu>
        <Translations {menu}/>
      </Route>

      <Route path="/account/*" fsContent requiredRoles={menus} let:menu>
        <Account {menu}/>
      </Route>

      <Route path="/vendor/*" fsContent requiredRoles={menus} let:menu>
        <Vendor {menu}/>
      </Route>

    </div>

    <!-- footer -->
    <div class="footer small fsContentHorizontal justify-content-around p-2">
      <div><i class="{$conf.faType} fa-copyright"></i> {thisYear()} {$conf.title} - V{$conf.version}</div>
    </div>

  </div>
  <!--{/key}-->
</Route>


<style>
  :global(body){
    background-color:var(--colorBg)!important;
    color: var(--colorText)!important;
  }
  :global(body) {
    font-family: 'Questrial'!important;
  }

  :global(.I){
    color:var(--brandColor);
  }

  :global(.btn-global) {
    background-color:var(--buttonBgColor)!important;
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }
  :global(.btn-global:hover) {
    opacity:0.8;
  }
  :global(.btn-outline-global) {
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonBgColor)!important;
  }
  :global(.btn-outline-global:hover) {
    opacity:0.8;
    background-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }
  
  :global(.link-global) {
    color:var(--colorLink)!important;
  }

  :global(::-webkit-scrollbar) {
    width: 20px!important;
    height: 20px!important;
  }

  @media only screen and (max-width: 600px) {
    :global(::-webkit-scrollbar) {
      width: 5px!important;
      height: 5px!important;
    }
  }

  :global(::-webkit-scrollbar-thumb) {
    background: var(--brandColor)!important;
  }

  :global(.dropdown-menu) {
    padding: 0;
  }

  .footer {
    margin-top:0.5rem;
    z-index:2;
    background-color: var(--navbarBgColor);
    box-shadow: 0 0.5rem 1rem var(--navbarBgColor);
    border-color: rgba(0, 0, 0, 0);
  }

  :global(.cards) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 2rem;
  }

  :global(.anchor span) {
    color:var(--brandColor2);
    cursor:pointer;
  }
  :global(.anchor span:hover){
    font-weight:bold;
  }

  :global(.alert-info) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #f0fcff;
    --bs-alert-border-color: #f0fcff;
  }
  :global(.alert-warning) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #fff4cb;
    --bs-alert-border-color: #fff4cb;
  }
  :global(.alert-success) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #dcffd5;
    --bs-alert-border-color: #dcffd5;
  }


</style>

<svelte:head>
  <link href='https://fonts.googleapis.com/css?family=Questrial' rel='stylesheet'>
</svelte:head>
