<script>
  import { w, lang, route } from './stores.js'
  import { i18n, setRoute, matchRoute, menuRoute } from './h.js'

  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'

  export let menus = []
  export let back = false
  export let cancel = false
  export let title = false
  export let detail = false
  export let help = false
  export let noCompact = false

  $: isCompact = !noCompact && $w.media == "xs" ? true : false
  $: msize = isCompact ? 1 : 2
  $: title = menuRoute($route, menus)?.name || title;

</script>


<div class:isCompact class="my-{msize} d-flex flex-wrap align-items-center">
  <!-- back button -->
  {#if back}
  <button class="mb-1 me-{msize} btn btn-sm btn-global" on:click={()=>setRoute(back===true?"":back)}>
    <I solid circle-arrow-up-left me={!isCompact} />
    {#if !isCompact}<span>
      <I18n id="g--Back" />
    </span>{/if}
  </button>
  {/if}
  <!-- cancel button -->
  {#if cancel}
  <button class="mb-1 me-{msize} btn btn-sm btn-outline-dark" on:click={()=>setRoute(cancel===true?"":cancel)}>
    <I solid circle-xmark />
    <I18n id="g--Cancel" />
  </button>
  {/if}
  <!-- title -->
  <span class="title ucfirst">{@html i18n(title, $lang)}</span>
  {#if detail}<span class="detail ucfirst">&nbsp;/&nbsp;{i18n(detail, $lang)}</span>{/if}
</div>



{#if help}
<div class="alert alert-warning">
  {@html i18n(help, $lang)}
</div>
{/if}
<style>
  .title {
    font-size: 1.7rem;
    /*font-weight: bold;*/
    line-height: 2rem;
  }

  .detail {
    font-size: 1.5rem;
    font-weight: lighter;
  }

  .isCompact>* {
    font-size: 1rem !important;
    line-height: 1rem !important;
  }
</style>