<script>
	import { conf, token, userId, vendorId, customerId, user, wsc } from './stores.js'
	import { apiGet, apiPost, logout, i18n, thisYear, ucFirst, setRoute, locale } from './h.js'

	import I18n from './G_I18n.svelte'
	import Route from './G_Route.svelte'
	import Form from './G_Form.svelte'
	import Input from './G_Input.svelte'
	import Button from './G_Button.svelte'
	import Alert from './G_Alert.svelte'

	let localAlert = {}
	let showPwd
	let authPwd
	let authId
	let otp2fa
	let showOtp2fa = false;

	if ($conf.qss.action == "try") setRoute('/auth/signUp')


	$: if (!$token) setRoute('/auth/signIn')
	$: if ( ( !$userId || ( $userId && $user.customerId!=$customerId ) ) && $token ) profil() //if userId not definer or if we change of customer

  async function profil() {
		let api = await apiGet(`/profil/_customerId_`, {}, { spinner: false, noThrow:true })
		if (api?.data) {
			$user = api.data
			locale($user.lang);
			$wsc.subscribe('user_' + $user.id)
			setRoute()
			return
		} else logout()
  }

	async function signIn() {
		if (!authId || !authPwd || (!otp2fa && showOtp2fa)) return
		localAlert = {}
		showOtp2fa = false
		let api = await apiPost(`/auth/login`, { authId: authId, authPwd: authPwd, otp2fa: otp2fa, vendorId: $vendorId }, {}, { spinner: false, no401AutoLogout: true, noThrow:true })
		if (api?.data?.token) {
			$token = api.data.token
		} else if (api.data?.otp2fa) {
			localAlert.success = api.message
			showOtp2fa = true
		} else {
			otp2fa = ''
			localAlert.danger = api.message
		}
	}

	async function signUp() {
		localAlert = {}
		let api = await apiPost(`/auth/guest`, { vendorId: $vendorId }, {}, { spinner: false, no401AutoLogout: true })
		if (api.data?.token) {
			$token = api.data.token
			setRoute('/')
		} else {
			localAlert.danger = api.message
		}
	}

	async function forgotPwd() {
		if (!authId) return
		localAlert.success = (await apiPost(`/auth/forgotPwd`, { authId: authId, vendorId: $vendorId, asCustomerId: $conf.asCustomerId }, {}, { no401AutoLogout: true })).message
	}

	async function sendOtp() {
		if (!authId) return
		localAlert.success = (await apiPost(`/auth/sendotp`, { authId: authId, vendorId: $vendorId, asCustomerId: $conf.asCustomerId }, {}, { spinner: false, no401AutoLogout: true })).message
	}
</script>

<Route path="/auth/*" valid={!$userId && !$token} Fade>
	<div style="z-index:10; background-image: url({$conf.splash});" class="authBgImg fs blur"></div>
	<div class="shadow-lg position-absolute top-50 start-50 translate-middle col-10 col-md-6 col-lg-4" style="z-index: 11;
		background-color:  white;
		background-color:  var(--authBgColor);
		padding: 26px;
		border-radius: 10px;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;">
		<p class="text-center">
			<a href={$conf.companyUrl}>
				<img max-height="50px" src={$conf.logo} class="img-fluid" style="max-height: 150px;" alt="logo" />
			</a>
		</p>
		<p class="h3 text-center">{i18n($conf.slogan)}</p>

		<Alert local={localAlert} />


		<Form formValid>
			<Route path="/auth/signIn" valid={$conf.signInOpen}>
				{#if showOtp2fa}
				<Input bind:value={otp2fa} placeholder="g--otp" required />
				{:else}
				<Input bind:value={authId} type="email" placeholder="g--mail" required />
				<Input bind:value={authPwd} showPassword type="password" noPattern placeholder="g--password" />
				<div class="small text-center pointer">
					<div on:click={forgotPwd}>
						<I18n>Forgot your password?</I18n>
					</div>
				</div>
				{/if}

				<Button validity action={signIn} lg class="mt-4 mb-2" title="Sign in" />

				{#if $conf.signOtp && !showOtp2fa}
				<div class="small text-center">
					<I18n>OR</I18n>
				</div>
				<Button validity action={sendOtp} class="mt-4 mb-2" title="Send otp code" />
				{/if}

				{#if $conf.signUpOpen}
				<div class="small text-center pointer">
					<a route=false href="./#/auth/signUp">
						<I18n>Sign up</I18n>
					</a>
				</div>
				{/if}
			</Route>


			<Route path="/auth/signUp" valid={$conf.signUpOpen}>
				<div class="text-center">
					<I18n>g--signUp help</I18n>
				</div>
				<Button action={signUp} lg class="mt-4 mb-2" title="Sign up" />
				{#if $conf.signInOpen}
				<div class="small text-center pointer">
					<a route=false href="./#/auth/signIn">
						<I18n>Sign in</I18n>
					</a>
				</div>
				{/if}
			</Route>
		</Form>

		<div class="text-center small mt-4 globalText">
			<i class="fa-solid fa-copyright" aria-hidden="true"></i> {thisYear()} - <a class="link" target="new" href={$conf.companyUrl}>{$conf.title}</a> - V{$conf.version} - {ucFirst($conf.htmlRoot)}
		</div>
	</div>
	<!--div data-for-info="to avoid complain of lighthouse"><label for="email">.</label><label for="password">.</label></div-->
</Route>

<style>
	.authBgImg {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		-webkit-filter: blur(5px);
		/* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
		filter: blur(5px);
	}
</style>