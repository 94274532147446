<script>
  import I18n from './G_I18n.svelte'
  import { spinner } from './stores.js'

  export let global = false //Little spinner OR Full global Spinner
  export let message = ''

  let minTimeBeforeShowSpinner = 50
  let spinnerShow
  let spinnerShowTimeout
  $: if (global && $spinner) spinnerShowTimeout = setTimeout(() => {
    if ($spinner) spinnerShow = true
  }, minTimeBeforeShowSpinner)
  $: if (global && !$spinner) { clearTimeout(spinnerShowTimeout); spinnerShow = false; }


</script>

{#if !global}
  <div class="container">
    <i class="fa-solid fa-spinner-third fa-spin fa-2x text-muted fa-fw"></i>
    <p>
      <I18n>{message}</I18n>
    </p>
  </div>
{:else}
  {#if spinnerShow}
    <div class="fullScreen spinnerGlobal">
      <div>
        <i class="fa-solid fa-spinner-third fa-spin fa-3x  fa-fw"></i>
        <p>
          <I18n>g--Please wait</I18n>
        </p>
      </div>
    </div>
  {/if}
{/if}


<style>
  .spinnerGlobal {
    background: rgba(0, 0, 0, 0.1);
    z-index: 2000;
  }

  .spinnerGlobal div {
    position: absolute;
    color: grey;
    top: 50%;
    width: 100%;
    text-align: center;
  }
</style>