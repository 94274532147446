<script>
  import { user, alert, conf, lang, modal } from './stores.js'
  import { api, apiGet, apiPost, apiDelete, setRoute, asArray, i18n, locale } from './h.js'
  import Alert from './G_Alert.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import Title from './G_Title.svelte'
  import Img from './G_Img.svelte'

  let payload = {}, obj = {}, objs = [], _razc=0;
  const apiZ = async (reload, what, ...rest) => { const ret = await api(...rest); what === "payload" ? payload = ret || {}: what === "obj" ? obj = ret?.data || {} : what === "objs" ? objs = ret?.data  || []: null; };
  const reloadApiZ = ()=>{_razc++;}

  const langOptions = $conf.langs.map(lang => ({
    id: lang,
    name: $conf.langList[lang].name
  }));

  async function setUser() {
    let payload = await apiPost(`/profil`, obj)
    if (payload.success) {
      $alert.success = payload.message;
      $user = { ...$user, ...payload.data };
      locale($user.lang);
      setRoute();
    }
  }

  async function proposePassword() {
    return (await apiGet(`/profil/genPwd`)).data
  }

  async function leaveCustomer(selectedCustomer = {}) {
    $modal = {
      cancel: true, title: `${i18n("leave customer")}  ${selectedCustomer.name}`, message: i18n("leave customer"), color: 'danger', dangerZone: selectedCustomer.name, confirm: async () => {
        await apiDelete(`/profil/${selectedCustomer.id}`)
        $user = {}
      }
    }
  }

</script>

<div class="container fsContent">
  <Title title="g--My profil" />
  <div class="fsScroll">
    {#await apiZ(_razc, `obj`, `get`, `/profil`) then}
    <Form>
      <Input bind:value={obj.email} type="email" icon label="Email" placeholder="g--email" required />
      <Input bind:value={obj.name} icon="user" label="Name" placeholder="g--name" required />
      <Input set={$lang} bind:value={obj.lang} type="select" options={langOptions} icon label="Preferred language" required />
      <Input bind:value={obj.password} type="password" {proposePassword} pattern showStrength showPassword label="Password" icon placeholder="g--Leave blank to keep the current" />
      {#if $conf.sign2FA}
      <Input set={false} lg type="switch" option="2FA" bind:value={obj.is2fa} />
      {/if}
      <Button validity action={setUser} actionModel="save" class="col-auto" />
    </Form>
    <hr />
    {#if obj?.customers}
    <Title title="g--Attached accounts" />
    <div class="non-selectable row">
      {#each asArray(obj.customers) as customer}
      <div class="col col-lg-4 py-2">
        <div class="card d-flex flex-row align-items-center">
          <div class="p-2">
            <Img maxh=50 src={customer.icon} alt={customer.name} />
          </div>
          <div class="p-2">{customer.name}</div>
          {#if customer.usersCanLeaveCustomer}
          <Button obj={customer} {leaveCustomer} actionModel="leave" class="p-2 ms-auto" />
          {/if}
        </div>
      </div>
      {/each}
    </div>
    {/if}
    {:catch}
    <Wrong />
    {/await}
  </div>
</div>