<script>
  import { conf, route, user, customerId } from './stores.js'
  import { asArray, matchRoute, ucFirst } from './h.js'

  export let menus=[]
  
  $: showCustomerSelector = menus.find(menu => menu.customerSelector!==false && matchRoute($route, menu.route + '/*'));

</script>

<div class="me-2 customerSelectShow w-xxl" class:invisible={!showCustomerSelector}>
  {#if asArray($user.customers).length>1 && !$conf.asCustomerId}
    <select class="form-select" bind:value={$customerId} name="customerSelector">
      {#each asArray($user.customers) as customer}
        <option value={customer.id} select={$customerId==customer.id}>
          {customer.name}
        </option>
      {/each}
    </select>
  {:else }
    <div class="fs-4">{ucFirst(asArray($user?.customers)?.[0]?.workspace || asArray($user?.customers)?.[0]?.name)}</div>
  {/if}
</div>

<style>
  .customerSelectShow {
    visibility: visible;
  }

  @media (min-width: 1400px) {
    .w-xxl {
      width: 20% !important;
    }
  }

  .w-xxl {
    width: 50%;
  }
</style>