import App from './G_App.svelte'
import { on, isCordovaReady } from './h.js'

let app

//wait ready event comming from isCordovaReady fct
on('ready', function(e) {
	app = new App({
		target: document.body,
		hydrate: true,
		props: {
			context: window.context
		},
		intro: true
	})
})

//ready take care if we are in cordova.
isCordovaReady()

export default app;