<script>
    import { route, conf, user, customerId } from './stores.js'

    import Window from './G_Window.svelte'
    import Route from './G_Route.svelte'

    import Dashboard from './L__Dashboard.svelte'
    import Cars from './L__Cars.svelte'
    import Streets from './L__Streets.svelte'
    import Scan from './L__Scan.svelte'
    import Statistics from './L__Statistics.svelte'


    $: if ($conf.asCustomerId) $customerId = $conf.asCustomerId;

    $: menus = [
        { route: '/homenocustomer', name: '', icon:'home', valid:!$customerId},
        { route: '/dashboard', name: 'Dashboard', icon: 'home', roles: ['manager'] },
        { route: '/statistics', name: 'Statistics', icon: 'chart-simple', roles: ['manager'] },
        { route: '/cars', name: 'Cars', icon: 'cars', roles: ['manager', 'service'] },
        { route: '/scan', name: 'Scan', icon: 'scanner-gun', roles: ['manager', 'service', 'scan'] },
        { route: '/streets', name: 'Streets', icon: 'road', roles: ['manager'] },
        { route: '/account', name: 'Account', icon: 'wrench', roles: ['customer_admin'] },
        { route: '/vendor', name: 'Vendor', icon: 'university', customerSelector: false, roles: ['vendor_manager'] },
        { route: '/translations', name: 'Translations', icon: 'globe', customerSelector: false, roles: ['vendor_admin'] }
    ]

    let localConf = {
        faType: "fa-light",
        faSharp: false,
        account: {
            withDomains: false,
            withBilling: false,
            withApi: false,
            withSupport: true,
            withDPO: true,
            withComparePlan: false,
            withLicence: true,
            withLicenceDetail: true,
            withSponsorship: false
        },
        vendor: {
            withLicenceVendorCustomer: true,
            withLicenceVendorCustomerDetail: true,
            withSponsorship: true
        },
        cname: "customer.klicat.com",
        carTypes: [{ id: "resident", name: "resident" }, { id: "professional", name: "professional" }],
        countries: [
            { "symbol": "AFG", "value": "AF", "name": "Afghanistan" },
            { "symbol": "AL", "value": "AL", "name": "Albania" },
            { "symbol": "DZ", "value": "DZ", "name": "Algeria" },
            { "symbol": "AND", "value": "AD", "name": "Andorra" },
            { "symbol": "ANG", "value": "AO", "name": "Angola" },
            { "symbol": "AG", "value": "AG", "name": "Antigua and Barbuda" },
            { "symbol": "RA", "value": "AR", "name": "Argentina" },
            { "symbol": "AM", "value": "AM", "name": "Armenia" },
            { "symbol": "AUS", "value": "AU", "name": "Australia" },
            { "symbol": "A", "value": "AT", "name": "Austria" },
            { "symbol": "AZ", "value": "AZ", "name": "Azerbaijan" },
            { "symbol": "BS", "value": "BS", "name": "Bahamas" },
            { "symbol": "BRN", "value": "BH", "name": "Bahrain" },
            { "symbol": "BD", "value": "BD", "name": "Bangladesh" },
            { "symbol": "B", "value": "BB", "name": "Barbados" },
            { "symbol": "BY", "value": "BY", "name": "Belarus" },
            { "symbol": "B", "value": "BE", "name": "Belgium" },
            { "symbol": "BH", "value": "BZ", "name": "Belize" },
            { "symbol": "DY", "value": "BJ", "name": "Benin" },
            { "symbol": "BHT", "value": "BT", "name": "Bhutan" },
            { "symbol": "BOL", "value": "BO", "name": "Bolivia" },
            { "symbol": "BIH", "value": "BA", "name": "Bosnia and Herzegovina" },
            { "symbol": "RB", "value": "BW", "name": "Botswana" },
            { "symbol": "BR", "value": "BR", "name": "Brazil" },
            { "symbol": "BRU", "value": "BN", "name": "Brunei" },
            { "symbol": "BG", "value": "BG", "name": "Bulgaria" },
            { "symbol": "BF", "value": "BF", "name": "Burkina Faso" },
            { "symbol": "RU", "value": "BI", "name": "Burundi" },
            { "symbol": "CV", "value": "CV", "name": "Cabo Verde" },
            { "symbol": "K", "value": "KH", "name": "Cambodia" },
            { "symbol": "CAM", "value": "CM", "name": "Cameroon" },
            { "symbol": "CDN", "value": "CA", "name": "Canada" },
            { "symbol": "RCA", "value": "CF", "name": "Central African Republic" },
            { "symbol": "TCH", "value": "TD", "name": "Chad" },
            { "symbol": "RCH", "value": "CL", "name": "Chile" },
            { "symbol": "CN", "value": "CN", "name": "China" },
            { "symbol": "CO", "value": "CO", "name": "Colombia" },
            { "symbol": "KM", "value": "KM", "name": "Comoros" },
            { "symbol": "RC", "value": "CD", "name": "Congo" },
            { "symbol": "CR", "value": "CG", "name": "Costa Rica" },
            { "symbol": "HR", "value": "HR", "name": "Croatia" },
            { "symbol": "C", "value": "CU", "name": "Cuba" },
            { "symbol": "CY", "value": "CY", "name": "Cyprus" },
            { "symbol": "CZ", "value": "CZ", "name": "Czech Republic" },
            { "symbol": "ZRE", "value": "CD", "name": "Democratic Republic of the Congo" },
            { "symbol": "DK", "value": "DK", "name": "Denmark" },
            { "symbol": "DJ", "value": "DJ", "name": "Djibouti" },
            { "symbol": "WD", "value": "DM", "name": "Dominica" },
            { "symbol": "DOM", "value": "DO", "name": "Dominican Republic" },
            { "symbol": "EC", "value": "EC", "name": "Ecuador" },
            { "symbol": "ET", "value": "EG", "name": "Egypt" },
            { "symbol": "ES", "value": "SV", "name": "El Salvador" },
            { "symbol": "GQ", "value": "GQ", "name": "Equatorial Guinea" },
            { "symbol": "ER", "value": "ER", "name": "Eritrea" },
            { "symbol": "EST", "value": "EE", "name": "Estonia" },
            { "symbol": "SD", "value": "SZ", "name": "Eswatini" },
            { "symbol": "ETH", "value": "ET", "name": "Ethiopia" },
            { "symbol": "FJI", "value": "FJ", "name": "Fiji" },
            { "symbol": "FIN", "value": "FI", "name": "Finland" },
            { "symbol": "F", "value": "FR", "name": "France" },
            { "symbol": "G", "value": "GA", "name": "Gabon" },
            { "symbol": "WAG", "value": "GM", "name": "Gambia" },
            { "symbol": "GE", "value": "GE", "name": "Georgia" },
            { "symbol": "D", "value": "DE", "name": "Germany" },
            { "symbol": "GH", "value": "GH", "name": "Ghana" },
            { "symbol": "GR", "value": "GR", "name": "Greece" },
            { "symbol": "WG", "value": "GD", "name": "Grenada" },
            { "symbol": "GT", "value": "GT", "name": "Guatemala" },
            { "symbol": "RG", "value": "GN", "name": "Guinea" },
            { "symbol": "GWB", "value": "GW", "name": "Guinea-Bissau" },
            { "symbol": "GUY", "value": "GY", "name": "Guyana" },
            { "symbol": "RH", "value": "HT", "name": "Haiti" },
            { "symbol": "H", "value": "HN", "name": "Honduras" },
            { "symbol": "H", "value": "HU", "name": "Hungary" },
            { "symbol": "IS", "value": "IS", "name": "Iceland" },
            { "symbol": "IND", "value": "IN", "name": "India" },
            { "symbol": "RI", "value": "ID", "name": "Indonesia" },
            { "symbol": "IR", "value": "IR", "name": "Iran" },
            { "symbol": "IRQ", "value": "IQ", "name": "Iraq" },
            { "symbol": "IRL", "value": "IE", "name": "Ireland" },
            { "symbol": "IL", "value": "IL", "name": "Israel" },
            { "symbol": "I", "value": "IT", "name": "Italy" },
            { "symbol": "JA", "value": "JM", "name": "Jamaica" },
            { "symbol": "J", "value": "JP", "name": "Japan" },
            { "symbol": "HKJ", "value": "JO", "name": "Jordan" },
            { "symbol": "KZ", "value": "KZ", "name": "Kazakhstan" },
            { "symbol": "EAK", "value": "KE", "name": "Kenya" },
            { "symbol": "KIR", "value": "KI", "name": "Kiribati" },
            { "symbol": "KP", "value": "KP", "name": "Korea (North)" },
            { "symbol": "ROK", "value": "KR", "name": "Korea (South)" },
            { "symbol": "KWT", "value": "KW", "name": "Kuwait" },
            { "symbol": "KS", "value": "KG", "name": "Kyrgyzstan" },
            { "symbol": "LAO", "value": "LA", "name": "Laos" },
            { "symbol": "LV", "value": "LV", "name": "Latvia" },
            { "symbol": "RL", "value": "LB", "name": "Lebanon" },
            { "symbol": "LS", "value": "LS", "name": "Lesotho" },
            { "symbol": "LB", "value": "LR", "name": "Liberia" },
            { "symbol": "LAR", "value": "LY", "name": "Libya" },
            { "symbol": "FL", "value": "LI", "name": "Liechtenstein" },
            { "symbol": "LT", "value": "LT", "name": "Lithuania" },
            { "symbol": "L", "value": "LU", "name": "Luxembourg" },
            { "symbol": "RM", "value": "MG", "name": "Madagascar" },
            { "symbol": "MW", "value": "MW", "name": "Malawi" },
            { "symbol": "MAL", "value": "MY", "name": "Malaysia" },
            { "symbol": "MV", "value": "MV", "name": "Maldives" },
            { "symbol": "RMM", "value": "ML", "name": "Mali" },
            { "symbol": "M", "value": "MT", "name": "Malta" },
            { "symbol": "MH", "value": "MH", "name": "Marshall Islands" },
            { "symbol": "RIM", "value": "MR", "name": "Mauritania" },
            { "symbol": "MS", "value": "MU", "name": "Mauritius" },
            { "symbol": "MEX", "value": "MX", "name": "Mexico" },
            { "symbol": "FSM", "value": "FM", "name": "Micronesia" },
            { "symbol": "MD", "value": "MD", "name": "Moldova" },
            { "symbol": "MC", "value": "MC", "name": "Monaco" },
            { "symbol": "MN", "value": "MN", "name": "Mongolia" },
            { "symbol": "MNE", "value": "ME", "name": "Montenegro" },
            { "symbol": "MA", "value": "MA", "name": "Morocco" },
            { "symbol": "MOC", "value": "MZ", "name": "Mozambique" },
            { "symbol": "MYA", "value": "MM", "name": "Myanmar (Burma)" },
            { "symbol": "NAM", "value": "NA", "name": "Namibia" },
            { "symbol": "NAU", "value": "NR", "name": "Nauru" },
            { "symbol": "NEP", "value": "NP", "name": "Nepal" },
            { "symbol": "NL", "value": "NL", "name": "Netherlands" },
            { "symbol": "NZ", "value": "NZ", "name": "New Zealand" },
            { "symbol": "NIC", "value": "NI", "name": "Nicaragua" },
            { "symbol": "RN", "value": "NE", "name": "Niger" },
            { "symbol": "WAN", "value": "NG", "name": "Nigeria" },
            { "symbol": "NMK", "value": "MK", "name": "North Macedonia" },
            { "symbol": "N", "value": "NO", "name": "Norway" },
            { "symbol": "OM", "value": "OM", "name": "Oman" },
            { "symbol": "PK", "value": "PK", "name": "Pakistan" },
            { "symbol": "PAL", "value": "PW", "name": "Palau" },
            { "symbol": "PA", "value": "PA", "name": "Panama" },
            { "symbol": "PNG", "value": "PG", "name": "Papua New Guinea" },
            { "symbol": "PY", "value": "PY", "name": "Paraguay" },
            { "symbol": "PE", "value": "PE", "name": "Peru" },
            { "symbol": "RP", "value": "PH", "name": "Philippines" },
            { "symbol": "PL", "value": "PL", "name": "Poland" },
            { "symbol": "P", "value": "PT", "name": "Portugal" },
            { "symbol": "QA", "value": "QA", "name": "Qatar" },
            { "symbol": "RO", "value": "RO", "name": "Romania" },
            { "symbol": "RUS", "value": "RU", "name": "Russia" },
            { "symbol": "RWA", "value": "RW", "name": "Rwanda" },
            { "symbol": "KNA", "value": "KN", "name": "Saint Kitts and Nevis" },
            { "symbol": "WL", "value": "LC", "name": "Saint Lucia" },
            { "symbol": "WV", "value": "VC", "name": "Saint Vincent and the Grenadines" },
            { "symbol": "WS", "value": "WS", "name": "Samoa" },
            { "symbol": "RSM", "value": "SM", "name": "San Marino" },
            { "symbol": "STP", "value": "ST", "name": "Sao Tome and Principe" },
            { "symbol": "KSA", "value": "SA", "name": "Saudi Arabia" },
            { "symbol": "SN", "value": "SN", "name": "Senegal" },
            { "symbol": "SRB", "value": "RS", "name": "Serbia" },
            { "symbol": "SY", "value": "SC", "name": "Seychelles" },
            { "symbol": "WAL", "value": "SL", "name": "Sierra Leone" },
            { "symbol": "SGP", "value": "SG", "name": "Singapore" },
            { "symbol": "SK", "value": "SK", "name": "Slovakia" },
            { "symbol": "SLO", "value": "SI", "name": "Slovenia" },
            { "symbol": "SOL", "value": "SB", "name": "Solomon Islands" },
            { "symbol": "SP", "value": "SO", "name": "Somalia" },
            { "symbol": "ZA", "value": "ZA", "name": "South Africa" },
            { "symbol": "SSD", "value": "SS", "name": "South Sudan" },
            { "symbol": "E", "value": "ES", "name": "Spain" },
            { "symbol": "CL", "value": "LK", "name": "Sri Lanka" },
            { "symbol": "SUD", "value": "SD", "name": "Sudan" },
            { "symbol": "SME", "value": "SR", "name": "Suriname" },
            { "symbol": "S", "value": "SE", "name": "Sweden" },
            { "symbol": "CH", "value": "CH", "name": "Switzerland" },
            { "symbol": "SYR", "value": "SY", "name": "Syria" },
            { "symbol": "RC", "value": "TW", "name": "Taiwan" },
            { "symbol": "TJ", "value": "TJ", "name": "Tajikistan" },
            { "symbol": "EAT", "value": "TZ", "name": "Tanzania" },
            { "symbol": "T", "value": "TH", "name": "Thailand" },
            { "symbol": "TL", "value": "TL", "name": "Timor-Leste" },
            { "symbol": "TG", "value": "TG", "name": "Togo" },
            { "symbol": "TO", "value": "TO", "name": "Tonga" },
            { "symbol": "TT", "value": "TT", "name": "Trinidad and Tobago" },
            { "symbol": "TN", "value": "TN", "name": "Tunisia" },
            { "symbol": "TR", "value": "TR", "name": "Turkey" },
            { "symbol": "TM", "value": "TM", "name": "Turkmenistan" },
            { "symbol": "TUV", "value": "TV", "name": "Tuvalu" },
            { "symbol": "EA", "value": "UG", "name": "Uganda" },
            { "symbol": "UA", "value": "UA", "name": "Ukraine" },
            { "symbol": "UAE", "value": "AE", "name": "United Arab Emirates" },
            { "symbol": "GB", "value": "GB", "name": "United Kingdom" },
            { "symbol": "USA", "value": "US", "name": "United States of America" },
            { "symbol": "ROU", "value": "UY", "name": "Uruguay" },
            { "symbol": "UZ", "value": "UZ", "name": "Uzbekistan" },
            { "symbol": "VU", "value": "VU", "name": "Vanuatu" },
            { "symbol": "VEN", "value": "VE", "name": "Venezuela" },
            { "symbol": "VN", "value": "VN", "name": "Vietnam" },
            { "symbol": "YE", "value": "YE", "name": "Yemen" },
            { "symbol": "Z", "value": "ZM", "name": "Zambia" },
            { "symbol": "ZW", "value": "ZW", "name": "Zimbabwe" }
        ]
    }

</script>

<Window {localConf} {menus}>
    <Route path="/homenocustomer" fsContent>
        Hello home no customer
      </Route>
    <Route path="/dashboard" fsContent requiredRoles={menus}>
        <Dashboard />
    </Route>

    <Route path="/statistics" fsContent requiredRoles={menus}>
        <Statistics />
    </Route>

    <Route path="/cars/*" fsContent requiredRoles={menus}>
        <Cars />
    </Route>

    <Route path="/streets/*" fsContent requiredRoles={menus}>
        <Streets />
    </Route>

    <Route path="/scan/*" fsContent requiredRoles={menus}>
        <Scan />
    </Route>
</Window>