<script>
  import { conf, customerId, alert, modal } from './stores.js'
  import { apiGet, apiPost, cdnDocument } from './h.js'
  import { pick } from 'lodash'

  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Route from './G_Route.svelte'
  //import FontSelector from './G_FontSelector.svelte'

  import Wrong from './G_Wrong.svelte'

  export let menus = []

  $: menus = [
    { route: '/account/design', name: 'design', roles: ['customer_admin', 'customer_manager'] }
  ]

  $: if (obj) {
    $conf = { ...$conf, ...pick(cdnDocument(obj), ["style", "icon", "logo"]) } //-> it works to change design in real time but need to implement a rollback if not saved
  }

  let obj = {}

  async function getCustomer() {
    obj = await apiGet(`/customers/_customerId_`).data
  }

  async function setCustomer() {
    let ret = await apiPost(`/customers/_customerId_`, obj).data
    $alert.success = "Updated"
  }

  async function loadStreet() {
    $modal = {
      cancel: true, title: `${i18n("g--LoadStreet")} ${i18n(obj.name)}`, msg: i18n("g--Confirm loadStreet"), color: 'danger', dangerZone: 'yes', confirm: async () => {
        await apiGet(`/customers/_customerId_/streets/load`)
        $alert.success = "Updated"
      }
    }
  }
</script>

<Route path="/account/design" fsContent requiredRoles={menus}>
  {#await getCustomer() then}
  <div class="fsScroll">
    <Form>
      <Input type="hr" icon="fa-qrcode" label="QR Code design" />
      <Input class="col-sm-6" set="" bind:value={obj.icon} accept="image/png,image/jpeg,image/gif" type="file" label="g--Icon" icon />

      <Input class="col-6" bind:value={obj.templateModelCdn} icon="pdf" type="file" label="template model"
        helpBefore="<a location=true href='https://cdn.qrblue.fr/assets/modelQR-vierge-v2-{obj.mentionOnBadgePdfShow?"mention":"simple"}.pdf?dl=true'>{obj.mentionOnBadgePdfShow?"modelQR-mention.pdf":"modelQR-simple.pdf"}</a>" accept=".pdf" />
        
      <Input class="col-6" bind:value={obj.infoLink} required icon label="info link" />
      <Input class="col-4" bind:value={obj.cityId} icon label="city Id" />
      <Button validity action={loadStreet} noMargin title="update streets" noLabel class="col-auto" />
      <Input class="col-sm-6" set={false} lg type="switch" option="protect Qr Border" bind:value={obj.protectQrBorder} />
      <Input class="col-sm-6" set={true} lg type="switch" option="Encoding with url" bind:value={obj.urlEncoding} />

      <Input type="hr" icon="fa-file-certificate" label="Mentions" />
      <Input class="col-sm-6" set={true} lg type="switch" option="Show mention badge pdf" bind:value={obj.mentionOnBadgePdfShow} />
      <Input class="col-sm-6" set={true} lg type="switch" option="Show mention on self service" bind:value={obj.mentionOnSelfShow} />

      {#if obj.mentionOnBadgePdfShow}
      <Input class="col-12" rows=20 type="textarea" label="Mention badge pdf" maxlength=2000 bind:value={obj.mentionOnBadgePdf} />
      {/if}

      {#if obj.mentionOnSelfShow}
      <Input class="col-12" rows=20 type="textarea" label="Mention on self service" bind:value={obj.mentionOnSelf} />
      {/if}


      <Input type="hr" icon="fa-calendar-clock" label="Time period setup" />
      <Input class="col-sm-6" set={2} lg type="number" bind:value={obj.prolongateBlueResidentYear} label="Resident extension period (in years)"/>
      <Input class="col-sm-6" set={1} lg type="number" bind:value={obj.prolongateBlueProfessionalYear} label="Professional extension period (in years)"/>

      
      {#if obj.isFqdn}
      <Input type="hr" icon="fa-palette" label="Interface design" />
      <Input class="col-6" set="" bind:value={obj.logo} accept="image/png,image/jpeg,image/gif" type="file" label="g--Logo" icon />
      <Input class="col-sm-6" set="" bind:value={obj.splash} accept="image/png,image/jpeg,image/gif" type="file" label="g--Splash" icon />

      <Input class="col-sm-6" set="#E2E2E2" bind:value={obj.style.brandColor} type="color" smooth colorText label="g--brandColor" />
      <Input class="col-sm-6" set="#DAFFC3" bind:value={obj.style.brandColor2} type="color" smooth colorText label="g--brandColor2" />

      <Input class="col-sm-4" set="#E2E2E2" bind:value={obj.style.authBgColor} type="color" smooth colorText label="g--authBgColor" />
      <Input class="col-sm-4" set="#E2E2E2" bind:value={obj.style.navbarBgColor} type="color" smooth colorText label="g--navbarBgColor" />
      <!--Input class="col-sm-4" set="#000000" bind:value={obj.style.navbarTextColor} type="color" smooth colorText label="g--navbarTextColor"/-->
      <!--Input class="col-sm-4" set="1rem" bind:value={obj.style.navbarFontSize} type="text" smooth colorText label="g--navbarFontSize"/-->
      <Input class="col-sm-4" set="#000000" bind:value={obj.style.color} type="color" smooth colorText label="g--color" />

      <Input class="col-sm-4" set="#339999" bind:value={obj.style.buttonBgColor} type="color" smooth colorText label="g--buttonBgColor" />
      <Input class="col-sm-4" set="#FFFFFF" bind:value={obj.style.buttonTextColor} type="color" smooth colorText label="g--buttonTextColor" />
      <Input empty />

      <!--FontSelector selector/-->

      {/if}

      <Button validity action={setCustomer} actionModel="update" class="col-auto" />
    </Form>
  </div>
  {:catch}
  <Wrong />
  {/await}
</Route>