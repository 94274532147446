<script>
  import I18n from './G_I18n.svelte'
  export let checked=false
  export let label=''
  export let onChange=()=>{}

  let id= Math.floor(Math.random() *1000000)

</script>
<!--label class="switch" class:checked><input type="checkbox" bind:checked={checked}  bind:checked={checked}/><div></div></label-->

<span class="form-switch">
  <input bind:checked={checked} on:change={onChange} class="form-check-input" type="checkbox" id="switch{id}" aria-label="switch">
  {#if label}<label class="form-check-label ms-1" for="switch{id}"><I18n>{label}</I18n></label>{/if}
</span>


<style>
 .form-check-input:checked {
    background-color: green;
    border-color: green;
  }
</style>