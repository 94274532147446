<script>
	import { online, wscOnline } from './stores.js'
  import I18n from './G_I18n.svelte'


  export let global = false //Little info OR Full global 

	let navigatorOnline = false
	$: $online = ($wscOnline == -1 && navigatorOnline) || ($wscOnline && navigatorOnline)

	let timeoutOffline
	let showOffline=false
	$: if(!$online) {
		clearTimeout(timeoutOffline)
		timeoutOffline=setTimeout(() => {
			showOffline=true
			//console.log(`WSC : ${$wscOnline} - navigatorOnline : ${navigatorOnline} - Online ? : ${$online}`);
		}, 200);
	}
	$: if($online) {
		showOffline=false
		clearTimeout(timeoutOffline)
		//console.log(`WSC : ${$wscOnline} - navigatorOnline : ${navigatorOnline} - Online ? : ${$online}`);
	}

</script>

<svelte:window bind:online={navigatorOnline} />

{#if showOffline}
	{#if global}
		<div class="fullScreen offlineGlobal">
			<div class="blink h1">
				<span class="fa-stack">
					<i class="fa-light fa-cloud fa-stack-2x"></i>
					<i class="fa-solid fa-exclamation-triangle fa-stack-1x" style="color:Tomato"></i>
				</span>
				<span><I18n id='No connection'/></span>
			</div>
		</div>
	{:else}
		<div class="offline">
			<div class="blink h4">
				<span class="fa-stack">
					<i class="fa-light fa-cloud fa-stack-2x"></i>
					<i class="fa-solid fa-exclamation-triangle fa-stack-1x" style="color:Tomato"></i>
				</span>
			</div>
		</div>
	{/if}
{/if}

<style>
	.offline {
		position:absolute;
		opacity:0.9;
		z-index: 3000;
	}
	.offlineGlobal {
		background: black;
		opacity:0.9;
		z-index: 3000;
	}
	.offlineGlobal div {
		position:absolute;
		color:white;
		top: 50%;
		width:100%;
		text-align: center;
	}
</style>
