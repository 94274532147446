<script>
  import { onMount } from 'svelte'
  import { tick } from 'svelte'
  import { on, sleep } from "./h.js"
 
  export let formValid=false
  export let norow=false //remove row class
  export let fsContent=false //add fsContent class

  let me, requireds, btnsValidity, countNotValid=0
  const validity=async ()=>{
    countNotValid=0
    formValid=false
    //await sleep(20) //we wait a little to permit dom to full refresh
    await tick() //we wait svelte tick to permit dom to full refresh
    if(!me) return
    //if(evt) console.log(evt)
    requireds=me.querySelectorAll("input, textarea, select") //[required], [pattern]
    if(requireds.length) requireds.forEach(element => {
      //if(element.type=="file") console.log(element)
      if(!element.validity.valid && element.type!="file") countNotValid++
      //if(element.type=="file" && !element.getAttribute('isvalid')) countNotValid++
      //console.log(element, element.validity) //there is an issue with file, validity is false when showing  an existent form...
    })
   if(!countNotValid) formValid=true

   //console.log(countNotValid, formValid)

   btnsValidity=me.querySelectorAll("[validity=true]")
   if(btnsValidity.length) btnsValidity.forEach(element => {
      if(formValid) element.disabled = false
      else element.disabled = true
      //console.log(element)
    })
  }

  /*onMount(() => {// not more needed since use of valueChanged event
    setTimeout(() => {
      validity() //put a little timeout to let form be filled before applying validity at mount time
    }, 400)
	})*/
  
  on("valueChanged", validity) 

  let cl=$$props.class || ''
</script>

<form class="{cl}" bind:this={me} on:OLDkeyup={validity} on:OLDchange={validity} on:OLDinput={validity} class:fsContent={fsContent} class:row={!norow}>
  <slot/>
</form>