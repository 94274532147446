<script>
  import { conf, modal, customerId, customer, alert } from './stores.js'
  import { apiGet, apiPost, apiDelete, i18n, setRoute, anchor, debounce, dateCalculate, tz } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs = [], obj = {}, keyword = "", sortBy = { plate: 1 }, filter = {}, tagsSelected = []

  let countriesNamed = $conf.countries.map(country => ({ ...country, name: `${country.symbol} - ${country.name} ` }))
  let countriesSorted = countriesNamed.sort((a, b) => a.name.localeCompare(b.symbol));

  let filters = [
    { filter: {}, title: 'g--All' },
    { filter: { validityDate: { $lt: new Date() } }, title: 'g--expired' },
    { filter: { type: "resident" }, title: 'g--residential' },
    { filter: { type: "professional" }, title: 'g--professional' },
    { filter: { valid: true }, title: 'g--valid' },
    { filter: { valid: false }, title: 'g--invalid' },
  ]

  let sortBys = [
    { id: 'plate', title: 'g--By plate' },
    { id: 'type', title: 'g--By car type', order: -1 },
    { id: 'validityDate', title: 'g--By validity date' },
    { id: 'valid', title: 'g--By valid' }
  ]

  let fields = [
    { id: "plate", title: "g--plate" },
    { id: "country", title: "g--country", options: $conf.countries },
    { id: "type", title: "g--type", options: $conf.zones },
    { id: "validityDate", title: "g--validity date", format: "dmy" },
    { id: "valid", title: "g--valid" }
  ]


  const list = debounce(listD, 200);
  async function listD() {
    if (!keyword?.replace(/[^a-zA-Z0-9]/g, '') || keyword?.length < 2) { objs = []; return }
    let existentTags = tagsSelected ? tagsSelected.filter((t) => { return tags.includes(t) }) : false
    let req = { ...filter, ...(existentTags.length ? { tags: { $in: existentTags } } : {}) }
    objs = await apiGet(`/customer/${$customerId}/cars`, { filter: req, keyword: keyword?.replace(/[^a-zA-Z0-9]/g, ''), sortBy: sortBy }).data
  }

  async function edit(id) {
    setRoute(`/cars/edit/${id}`)
  }

  async function update() {
    try {
      obj = await apiPost(`/customer/${$customerId}/cars`, obj).data
      //keyword = obj.plate
      list()
      //getTags()
      setRoute("/cars")
    } catch (err) {
      //$alert.danger = "existing plate"
      //throw new Error('existing plate');

      $modal = {
        cancel: true, title: `${i18n("existing plate")} ${i18n(obj.name)}`, msg: i18n("g--Search") + " " + obj.plate, color: 'warning', confirm: async () => {
          keyword = obj.plate
          list()
          setRoute("/cars")
        }
      }
    }
  }

  async function get(id) {
    obj = {}
    if (id) obj = await apiGet(`/customer/${$customerId}/cars/${id}`).data
  }

  $: if (obj && obj?.type && !obj.id) {
    obj.validityDate = new Date(dateCalculate(null, obj.type == "professional" ? 1 : 2, "year", null, "start"))
  }


  async function del() {
    $modal = {
      cancel: true, title: `${i18n("g--Delete")} ${i18n(obj.name)}`, msg: i18n("g--Confirm delete"), color: 'danger', dangerZone: 'yes', confirm: async () => {
        await apiDelete(`/customer/${$customerId}/cars/${obj.id}`)
        list()
        setRoute("/cars")
      }
    }
  }


  let tags = []
  //getTags()
  async function getTags() {
    tags = await apiGet(`/customer/${$customerId}/cars/tags`).data
  }

  let streets = []
  getStreets()
  async function getStreets() {
    streets = await apiGet(`/customer/${$customerId}/streets?composed=true`).data
    streets.unshift({id:"",name:""});
  }

  let importCarsFile
  async function importCars() {
    $modal = {
      cancel: true, title: `${i18n("g--Import")} ${i18n(obj.name)}`, msg: i18n("g--Confirm import"), color: 'danger', dangerZone: 'yes', confirm: async () => {
        if (!importCarsFile) return
        await apiPost(`/customer/${$customerId}/cars/import`, { cars: importCarsFile })
        $alert.success = "imported ok"
        importCarsFile = null;
        list()
        setRoute("/cars")
      }
    }
  }

  async function prolongate() {
    $modal = {
      cancel: true, title: `${i18n("g--Prolongate")} ${i18n(obj.name)}`, msg: i18n("g--Confirm prolongate"), color: 'danger', confirm: async () => {

        let yearProlongate=1;
        if(obj.type=="resident") yearProlongate=2;
        if(obj.type=="professional") yearProlongate=1;
        obj.validityDate = dateCalculate((new Date(obj.validityDate) > new Date())?obj.validityDate:new Date() , yearProlongate, "year", null, "start")
        await update()
      }
    }
  }

  async function prolongateAndPrint() {
    $modal = {
      cancel: true, title: `${i18n("g--ProlongateAndPrint")} ${i18n(obj.name)}`, msg: i18n("g--Confirm prolongateAndPrint"), color: 'danger', confirm: async () => {

        let yearProlongate=1;
        if(obj.type=="resident") yearProlongate=2;
        if(obj.type=="professional") yearProlongate=1;
        obj.validityDate = dateCalculate((new Date(obj.validityDate) > new Date())?obj.validityDate:new Date() , yearProlongate, "year", null, "start")
        await update()
        printQRPage()
      }
    }
  }

  async function updateAndPrint() {
    try {
      await update()
      printQRPage()
    } catch (err) {
    }

  }



  function printQRPage() {
    const shortId = obj.shortId
    //need qrcode route: printing can be only request from the same domain
    console.log($conf.apiUrl)
    var windowName = "PopupWindow";
    var windowFeatures = "width=500,height=500,scrollbars=no";

    let popup = window.open(`/api/v5/public/customer/${$customerId}/selfservice/car/pdf/${shortId}?tz=${tz()}`, windowName, windowFeatures)
    popup.onafterprint = (e) => { popup.close() };//not working
    //popup.onbeforeunload= (e)=>{alert("coucou2")};
    setTimeout(function () {
      popup.focus()
      popup.print()
      popup.onfocus = function () { setTimeout(function () { popup.close(); }, 20000); }//force to close the window after 20s
    }, 2000)
  }
</script>


<Route path="/cars/*" pathHide="/cars/+" fsContent>
  <div class="container fsContent">

    <Title title back />

    <FilterLine {tags} bind:tagsSelected search bind:keyword placeholder="Search (Minimum 2 characters)" createRoles={["c_admin","c_manager","c_service"]} createBulkRoles={["c_admin","c_manager"]}
      create={()=>
      setRoute("/cars/edit")} createBulk={()=>setRoute("/cars/import")} createBulkLabel={"Import"} dl={false}
      bind:filter {filters} bind:sortBy {sortBys} />
      {#await list(keyword, filter, sortBy) then}
      <List onSelect={edit} refresh={list} title="g--cars" {objs} {fields} />
      {/await}
  </div>
</Route>


<Route path="/cars/import" fsContent>
  <div class="container fsContent">
    <Title title detail="import" back="/cars" />
    <Input type="file" bind:value={importCarsFile} required accept=".csv" helpBefore="<a location=true href='https://cdn.qrblue.fr/assets/carsModel.csv'>model</a>" />
    <Button validity {importCars} actionModel="save" class="col-auto" />
  </div>
</Route>


<Route path="/cars/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

  <div class="container fsContent">
    <Title title detail={obj.plate} back="/cars" />

    <!--div class="anchor" on:click={anchor}>
      <span><I18n>Description</I18n></span>
    </div-->

    <Form fsContent norow>
      <!--{#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}-->
      <div class="row fsScroll">
        <Input set={true} bind:value={obj.valid} type="switch" lg option="g--Valid" />
        {#if obj.legacyId}
        <Input bind:value={obj.legacyId} required readonly type="text" icon="fa-scroll-old" label="LegacyId" />
        {/if}
        <Input class="col-8" bind:value={obj.plate} minlength=4 maxlength=15 required readonly={obj.id} type="text" icon="fa-car-side" label="Plate" />
        <Input class="col-4" set="FR" bind:value={obj.country} type="select" icon="fa-earth-europe" label="Country" options={countriesSorted} />
        <Input bind:value={obj.validityDate} readonly set={dateCalculate(null, 2, "year" , null, "start" )} required type="date" icon label="Validity date" />
        <Input bind:value={obj.type} type="radio" inline lg required icon="fa-building" label="Type" options={$conf.carTypes} />
        {#if obj.type=="professional"}
        <Input bind:value={obj.companyId} minlength=4 maxlength=30 required type="text" icon="fa-building" label="Company identification number" />
        {/if}
        <Input bind:value={obj.streetId} type="select" icon="fa-road" label="Street" options={streets} />
        <Input bind:value={obj.email} type="email" obfuscate={obj.id} icon label="email" help="email car help" />


        <div class="row">
          <!--Button validity cond={obj?.id} {del} class="col-auto" /-->
          <Button validity cond={obj?.id} {update} class="col-auto" />
          <Button validity cond={obj?.id && new Date(obj.validityDate) <=new Date(new Date().setMonth(new Date().getMonth() + 1))} {prolongate} title="Prolongate" color="warning" icon="clock"
            class="col-auto" />
          <Button validity cond={obj?.id} {printQRPage} title="Print badge" color="warning" icon="print" class="col-auto" />
          <Button validity cond={obj?.id && new Date(obj.validityDate) <=new Date(new Date().setMonth(new Date().getMonth() + 1))} {prolongateAndPrint} title="Prolongate and print badge"
            color="warning" icon="clock" class="col-auto" />
          <Button validity cond={!obj?.id} {update} actionModel="save" class="col-auto" />
          <Button validity cond={!obj?.id} {updateAndPrint} icon="print" title="Save and print badge" color="warning" class="col-auto" />
        </div>
      </div>
    </Form>
  </div>
  {:catch}
  <Wrong />
  {/await}
</Route>