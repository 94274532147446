<script>
  import { cdn } from './h.js'
  export let src=false
  export let fluid=false
  export let h=false
  export let w=false
  export let maxh=false
  export let maxw=false
  export let minh=false
  export let minw=false
  export let imgH="" //img html balise, not used in style
  export let imgW="" //img html balise, not used in style

  export let title="img"
  let cl=$$props.class || ''

  let style="", height=imgH, width=imgW

  if (!/[a-z%]+/.test(h)) h+="px"
  if (!/[a-z%]+/.test(w)) w+="px"
  if (!/[a-z%]+/.test(maxh)) maxh+="px"
  if (!/[a-z%]+/.test(maxw)) maxw+="px"
  if (!/[a-z%]+/.test(minh)) minh+="px"
  if (!/[a-z%]+/.test(minw)) minw+="px"

  if(h) {style+=`height:${h};`}
  if(w) {style+=`width:${w};`}
  if(maxh) {style+=`max-height:${maxh};`}
  if(maxw) {style+=`max-width:${maxw};`}
  if(minh) {style+=`min-height:${minh};`}
  if(minw) {style+=`min-width:${minw};`}


</script>
{#if src}
  <img class={cl} class:img-fluid={fluid} {style} src={cdn(src)} {height} {width} alt={title}>
{/if}