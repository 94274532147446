<script>
  import { conf, lang, customerId } from './stores.js'
  import { apiGet, translate, i18n, setRoute, sleep, inArray, isSame } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import Chart from './G_Chart.svelte'

  import { onDestroy } from 'svelte';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  const duration = 1000 * 60 ; // Specify the duration in milliseconds
  const intervalId = setInterval(() => { progress.set(100, { duration: 0 }).then(() => progress.set(0, { duration })); getDash(); }, duration)
  const progress = tweened(100, {
    duration: duration
  });

  onDestroy(() => clearInterval(intervalId))
  let dash = {}
  progress.set(0)
  getDash()


  $: dashboard = [
    { route: '/cars', name: 'Cars', icon: 'cars', value: dash.carsCount || 0, color: "success", valid: true },
    { route: '/streets', name: 'Streets', icon: 'road', value: dash.streetsCount || 0, color: "success", valid: true },
    { route: '/streets', name: 'Blue zones', icon: 'building', value: dash.streetsBlueCount || 0, color: "success", valid: true },
    { route: '/account/users', name: 'Users', icon: 'users', value: dash.usersCount || 0, color: "success", valid: true },
    { name: 'Badges imprimés', icon: 'print', value: dash.pdfPrintCount || 0, color: "success", valid: true },
    { name: 'Utilisation Self service', icon: 'chart-line-up', value: dash.selfSearchCount || 0, color: "success", valid: true },
    { name: 'Badges téléchargés en self service', icon: 'download', value: dash.selfPdfPrintCount || 0, color: "success", valid: true },
    { name: 'Expires dans les 15 jours', icon: 'date', value: dash.expireNear || 0, color: "success", valid: true },
    { name: 'Rappels dans les 15 jours', icon: 'bell', value: dash.reminds || 0, color: "success", valid: true },
    { name: 'Badges créés ce mois-ci', icon: 'calendar', value: dash.carsMonthCount || 0, color: "success", valid: true },
    { name: "Badges créés aujourd'hui", icon: 'calendar-day', value: dash.carsTodayCount || 0, color: "success", valid: true },
  ]

  let c = {
    lightblue: '#00FFB6',
    green: '#1AD604',
    red: '#F9344F',
    purple: '#9C27B0',
    blue: '#2F9BF4',
    orange: '#FF7903',
    pink: '#df01d8'
  }


  async function getDash() {
    return
    dash = await apiGet(`/customer/${$customerId}/dashboard`).data
    if (dash) dash.ok = true
    else dash = {}
  }


  /**** dashboard tiles ****/
  function colorByPercent(percent) {
    if (percent > 80) return 'success'
    if (percent > 50) return 'warning'
    return 'danger'
  }

  /**** Cars ****/
  let chartCarsValidityDate
  $: if (dash.ok) chartCarsValidityDate = {

    series: [dash.cars_valid, dash.cars_expired],
    labels: ['Valid', 'Expired'],

    colors: [c.green, c.red],
    chart: {
      height: 300,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      },
    }
  }

  let chartCarsZones
  $: if (dash.ok) chartCarsZones = {

    series: [dash.cars_professional, dash.cars_resident],
    labels: ['Professional', 'Resident'],

    colors: [c.pink, c.blue],
    chart: {
      height: 300,
      type: 'donut'
    },
    plotOptions: {
      pie: {
        donut: {
          size: 30
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      },
    }
  }


  let chartStreets
  $: if (dash.ok) chartStreets = {
    series: [
      {
        data: dash.streets
      }
    ],

    chart: {
      height: 300,
      type: 'treemap',
      toolbar: { show: false }
    },
    /*title: {
      text: 'Streets treemap',
      align: 'center'
    },*/
    colors: [
      '#3B93A5',
      '#F7B844',
      '#ADD8C7',
      '#EC3C65',
      '#CDD7B6',
      '#C1F666',
      '#D43F97',
      '#1E5D8C',
      '#421243',
      '#7F94B0',
      '#EF6537',
      '#C0ADDB'
    ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false
      }
    }
  };
</script>

<Route path="/dashboard" fsContent>
  <div class="container-fluid fsContent">

    <Title title="Dashboard" />

    <progress class="progress" value={$progress/100}></progress>

    <!--div class="wheel-container">
      <div class="wheel" style="transform: rotate({$angle}deg)"></div>
    </div-->

    <div class="fsScroll">
      <div class="row row-cols-1 row-cols-md-4 g-4">

        {#each dashboard as d}
        {#if d.valid}
        <div class="col" on:click={()=>{if (d.route) setRoute(d.route)}}>
          <div class="card h-100 shadow pointer" class:stop={!d.route}>
            <div class="card-body">
              <div class="card-dash">

                {#if d.type=="right"}
                <div>
                  <div class="text-{d.color}">{d.value}</div>
                  <div class="text">{i18n(d.name, $lang)}</div>
                </div>
                <div class="text-{d.color}"><I icon={d.icon} /></div>
                {:else}
                <div class="text-{d.color}"><I icon={d.icon} /></div>
                <div class="text-end">
                  <div class="text-{d.color}">{d.value}</div>
                  <div class="text">{i18n(d.name, $lang)}</div>
                </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
        {/if}
        {/each}
      </div>


      <!--div class="mt-1 row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xxl-6 g-4"-->
      <div class="mt-1 row g-4">
        <div class="col-lg-2" on:click={()=>setRoute('/cars')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">
              <I18n>Validity date</I18n>
            </div>
            <div class="card-body">
              <div>
                <Chart data={chartCarsValidityDate} />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-2" on:click={()=>setRoute('/cars')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">
              <I18n>Car type</I18n>
            </div>
            <div class="card-body">
              <div>
                <Chart data={chartCarsZones} />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-8" on:click={()=>setRoute('/streets')}>
          <div class="card h-100 pointer shadow">
            <div class="card-header">
              <I18n>Zones usage</I18n>
            </div>
            <div class="card-body">
              <div>
                <Chart data={chartStreets} />
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</Route>

<style>
  .card-dash {
    line-height: 2.5rem;
    font-size: 3rem;
    display: flex;
    justify-content: space-between;
  }

  .text {
    line-height: 1rem;
    font-size: 1rem;
  }

  .progress::-webkit-progress-value {
    background-color: var(--brandColor);
    /* Change the color for WebKit-based browsers */
  }

  progress {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 2px;
  }
</style>