import { writable, get, derived } from "svelte/store";
import _merge from "lodash/merge";

export const conf = writable({});
export const fonts = writable([]);
export const online = writable(false);
export const modal = writable({});
export const alert = writable({});
export const spinner = writable(false);

export const route = writable("/");
export const routeRoot = writable("/");
export const routeMatched = writable(false);
export const routeReload = writable(false);

export const routeU = writable("/");
export const routeRootU = writable("/");
export const routeMatchedU = writable(false);
export const routeReloadU = writable(false);

export const wsc = writable(false); // wss client
export const wscOnline = writable(-1); // wss client online state

export const w = writable({}); // window properties (height, width, xorigin, media, size)

/************************************ */
export const vendorId = derived(conf, ($conf) => $conf.id || false);

export const user = writable({}); // current user
export const userId = derived(user, ($user) => $user.id || false);

export const uuid = writable(doLocalStorage("uuid") || _uuid());
uuid.subscribe((value) => doLocalStorage("uuid", value));

export const lang = writable(doLocalStorage("lang") || "");
lang.subscribe((value) => doLocalStorage("lang", value));

export const pwaInstalled = writable(doLocalStorage("pwaInstalled") || "no");
pwaInstalled.subscribe((value) => doLocalStorage("pwaInstalled", value));

export const customerId = writable(doLocalStorage("customerId") || (get(user) && get(user).customers && Object.keys(get(user).customers)[0]) || '');
user.subscribe(user => {
  if (!doLocalStorage("customerId") || ( user?.customers && user.customers.every(customer => customer.id !== doLocalStorage("customerId")))){
    user?.customers && user.customers[0] && customerId.set(user.customers[0].id || '');
  }
});
customerId.subscribe((value) => doLocalStorage("customerId", value));

export const customer = derived(
  [user, customerId],
  ([$user, $customerId]) =>
    ($customerId && $user?.customers && $user.customers.find(customer => customer.id === $customerId)) || {}
);

export const token = writable(doLocalStorage("token") || null);
token.subscribe((value) => {
  doLocalStorage("token", value)
  if(!value) user.set({})
});


// --------------------------------------------
// ----- Local helper
// --------------------------------------------
/**
 *
 *
 * @param {*} id
 * @param {*} value
 * @return {*} 
 */
function doLocalStorage(id, value) {
  let prefix = "";
  //console.log(id, value);
  if (typeof localStorage !== "undefined") {
    if (value != null) localStorage[prefix + id] = JSON.stringify(value);
    else return JSON.parse(localStorage.getItem(prefix + id));
  }
}

/**
 *
 *
 * @param {*} noDash
 * @return {*} 
 */
function _uuid(noDash) {
  let f = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  if (noDash) f = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx";
  return f.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// --------------------------------------------
// ----- external stores access (-;)
// --------------------------------------------
let stores = {
  vendorId, //to delete ?
  w,  //to delete ?
  fonts,
  online,
  modal,
  alert,
  spinner,
  route,
  routeRoot,
  routeMatched,
  routeReload,
  routeU,
  routeRootU,
  routeMatchedU,
  routeReloadU,
  lang,
  uuid,
  pwaInstalled,
  conf,
  wsc,
  wscOnline,
  token,
  user,
  customerId,
  customer,
  //roles
};
export function _s(storeName, obj, replace = false) {
  if (!stores.hasOwnProperty(storeName)) return;
  if (obj != null) {
    if (replace) stores[storeName].set(obj);
    else
      stores[storeName].update((cur) => {
        if (typeof obj !== "object" || typeof cur !== "object") return obj;
        return _merge(cur, obj);
      });
    return get(stores[storeName]);
  } else return get(stores[storeName]);
}
