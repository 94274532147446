<script>
  import { conf, lang, customerId } from './stores.js'
  import { apiGet, i18n, setRoute, dateCalculate } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import Chart from './G_Chart.svelte'
  import DatesPicker from './G_DatesPicker.svelte'
  import FilterLine from './G_FilterLine.svelte'
  
  let today=new Date()
  let startDate=dateCalculate(today, -90, 'day', 'datePart')
  let todayDate=dateCalculate(today, 0, 'day', 'datePart')
  let endDate=todayDate

  $: preDates=[
    {name:'Last 90 days', startDate: dateCalculate(today, -90, 'day', 'datePart'), endDate: todayDate},
    {name:'Last 30 days', startDate: dateCalculate(today, -30, 'day', 'datePart'), endDate: todayDate},
    {name:'Last 7 days', startDate: dateCalculate(today, -7, 'day', 'datePart'), endDate: todayDate},
    {name:'This week', startDate: dateCalculate(today, 0, 'firstDayOfWeek', 'datePart'), endDate: todayDate},
    {name:'Yesterday', startDate: dateCalculate(today, -1, 'day', 'datePart'), endDate: dateCalculate(today, -1, 'day', 'datePart')},
    {name:'Today', startDate: todayDate, endDate: todayDate},
    {name:'Last year', startDate: dateCalculate(today, -1, 'firstDayOfYear', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfYear', 'datePart')},
    {name:'This year', startDate: dateCalculate(today, 0, 'firstDayOfYear', 'datePart'), endDate: todayDate},
    {name:'Last month', startDate: dateCalculate(today, -1, 'firstDayOfMonth', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfMonth', 'datePart')},
    {name:'This month', startDate: dateCalculate(today,0, 'firstDayOfMonth', 'datePart'), endDate: todayDate},
    {name:'Last week', startDate: dateCalculate(today, -1, 'firstDayOfWeek', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfWeek', 'datePart')},
    //{name:'All', startDate: dateCalculate(firstTracking, 0, 'day', 'datePart') ,endDate: todayDate}
  ]
</script>
Hello
<Route path="/statistics" fsContent>
  <div class="container-fluid fsContent">

    <Title title="Statistics" />

    <FilterLine>
      <div>
        {#each preDates as preDate}
          <div class:active={startDate==preDate.startDate && endDate==preDate.endDate} on:click={()=>{startDate=preDate.startDate; endDate=preDate.endDate}}><I18n>analytics--{preDate.name}</I18n></div>
        {/each}
      </div>
    </FilterLine>

    <DatesPicker bind:startDate bind:endDate maxDate={new Date()}/>


    <div class="fsScroll">
     
    </div>
  </div>
</Route>