<script>
  //npm install apexcharts
  //import ApexCharts from "apexcharts"
  import { lang } from './stores.js'
  import { check, i18n, loadJS } from './h.js'
  import I18n from './G_I18n.svelte'

  let isApexCharts=false
  import { onMount } from 'svelte';
  onMount(async () => {
    if(!window['ApexCharts']) await loadJS(`/js/3.41.0.apexcharts.min.js`)
    isApexCharts=true
  })



  export let data = {}
  $: isDataChart = checkDataEmpty(data)

  function checkDataEmpty() {
    data.noData= {
      text: "chart--Not enough data yet"
    }

    if (!data.series || !data.series.length) return false

    if (data?.labels) data.labels = data?.labels.map((label) => i18n(label, $lang));

    if (data?.title?.text) data.title.text = i18n(data.title.text, $lang);


    if ((data.chart?.type == "donut" || data.chart?.type == "pie") && data.series?.every(val => val == 0)) {
      data.series = [1]
      data.labels?.unshift('')
      data.colors?.unshift('#f0f0f0')
      data.plotOptions = {
        [data.chart?.type]: {
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: false
            },
            tooltip: { enabled: false }
          }
        }
      }
      return true
    }
    //if(check(data, "series[0].data").length && check(data, "series[0].name")) return true
    //if( check(data, "series").length  && !check(data, "series[0].name")) return true
    if (data?.series?.[0]?.data?.length && data?.series?.[0]?.name) return true
    if (data?.series?.length && !data?.series?.[0]?.name) return true
  }

  const chart = (node, options) => {
    let myChart = new ApexCharts(node, options)
    myChart.render()

    return {
      update(options) {
        myChart.updateOptions(options)
      },
      destroy() {
        myChart.destroy()
      }
    }
  }
</script>
{#if isDataChart && isApexCharts}
<div use:chart={data} />
{:else}
<div class="text-center"><i class="fa-light fa-exclamation-circle me-2"></i>
  <I18n id='chart--Not enough data yet' />
</div>
{/if}