<script>
  import { conf, lang , _s } from './stores.js'
  import { atobUnicode, console_with_styling, ucFirst, isSSR } from './h.js'
  import Start from './L_Start.svelte'
  export let context = {}

  //if (typeof window !== 'undefined' && window.context) {
  if (!isSSR() && window.context) {
    context = JSON.parse(atobUnicode(window.context))
  }
  $conf = context.conf || {}

  if (!isSSR()) console_with_styling(`BUILD WITH KLICAT SYSTEM - V${_s("conf").version} - /${_s("conf").htmlRoot} - ${ucFirst(_s("conf").serviceCode)}`);
</script>
<Start/>

<svelte:head>
  <meta name="theme-color" content="{$conf.metaThemeColor || 'grey'}">
</svelte:head>